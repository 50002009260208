
.holder{
    display: flex;
    justify-content: space-between;
    gap: 60px;
    margin-left: auto;
    margin-right: 0;
    background-color: #8ABCD7;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.contentFooter{
    display: flex;
    justify-content: space-between;
    align-items: left;
    gap: 60px;
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    padding: 10px;
    /* padding-left: 50%; */
}

.socialHolder{
    width: 33% !important;
}

@media screen and (max-width: 780px){
    .socialHolder{
        width: 100% !important;
    }
}