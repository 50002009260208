* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: white;
    font-size: 18px;
  }
  
  /* navbar */
  .navbar {
    background-color: #8ABCD7;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    min-height: 70px;
  }
  .nav-link {
    font-size: 18px;
  }
  
  
  /* logo  */
  .logo {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
  }

  .logo img {
    height: 75px;
    padding-top: 10px;
    padding-left: 5%;
  }
  
  
  /* ul  */
  .navMenu {
    display: flex;
    justify-content: space-between;
    align-items: right;
    gap: 60px;
    margin-left: auto;
    margin-right: 0;
    /* padding-left: 50%; */
  }

  .navLink {
    align-items: right;
    margin-right: 0px;
    color: white;
  }
  a:link {
    text-decoration: none;
  }
  
  a:visited {
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  a:active {
    text-decoration: underline;
  }

  
  /* media queries */
  
  @media screen and (max-width: 780px){
    
    /* hamburger */
    .hamburger {
      display: block;
      cursor: pointer;
    }
  
    .bar {
          background-color: #120f0b;
          width: 20px;
          height: 3px;
          display: block;
          margin: 5px;
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
      }
  
    /* adding active class to the bars  */
    .hamburger.active .bar:nth-child(2){
      opacity: 0;
    }
    .hamburger.active .bar:nth-child(1){
      transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3){
      transform: translateY(-8px) rotate(-45deg);
    }
  
  
    /* navMenu */
  
    .navMenu{
      position: absolute;
      flex-direction: column;
      gap: 0;
      top: 70px;
      left: -100%;
      text-align: start;
      width: 100%;
      transition: 0.7s ease-in-out;
      background-color: aliceblue;
      padding: 10px;
    }

    .navLink {
      align-items: right;
      margin-right: 0px;
      color: black;
    }
  
    .navMenu.active{
      left: 0;
    }
  
    .navMenu li {
      margin: 16px 0;
    }
  }
 

  .nav-link{
    color: white !important;
  }
