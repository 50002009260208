.add-shipments-header{
    color: #469FCE;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 20px;
}

.add-shipments-table-container{
    width: 100%;
    text-align: center;
    max-height: 490px;
    min-height: 490px;
    overflow-y: scroll;
}

.add-table {
    margin: auto;
    width: 95%;
    border-collapse: separate;
    border-spacing: 0;
    border: 2px solid #469FCE;
    border-radius: 10px;
    table-layout: fixed;
}

.add-table thead{
    color: #E1EFFE;
    background-color: #469FCE;
    font-size: 18px;
    position: sticky;
    top:0;
    z-index: 1;
    letter-spacing: 0.5px;
}

.add-table th {
    height: 80px;
}

.add-table tbody {
    color: #469FCE;
    background-color: transparent;
    font-size: 20px;
    font-weight: 400;
    border: none; 
}

.add-table td {
    height: 80px;
}

.add-table tbody tr:nth-child(odd) {
    background-color: #FFFFFF;
}

.add-table tbody tr:nth-child(even) {
    background-color: #D1ECFB;
}

.add-table td button {
    background-color: #469FCE;
    color: white;
    border: 1px solid #469FCE;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    width: 39px;
    height: 36px;
    font-weight: 400;
    font-size: 24px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 auto;
    padding-bottom: 6px;
}

.add-table td button:hover {
    background-color: #D1ECFB;
    border-color: #D1ECFB;
}

.add-table td p:hover {
    text-decoration: underline;
}

.value-box {
    display: inline-block;
    width: 51px;
    height: 55px;
    line-height: 45px;
    border: 4px solid #8ABCD7;
    border-radius: 15px;
    margin: 0 10px;
    background-color: #F5F5F5;
    vertical-align: middle;
    color: #469FCE; 
    text-align: center;
}

.value-box:focus {
    outline: transparent;
    border: 4px solid #E4976C;

}

.ship-info-input {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width:90%;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align:center;
}

.input-group {
    display:flex;
    flex-direction: column;
    width:380px;
}

.ship-info-input label, p {
    color: #469FCE;
    font-size: 20px;
    margin-bottom: 8px;
}

.ship-info-input input, select{
    color: #469FCE;
    font-size: 20px;
    padding: 8px;
    border: 1px solid #469FCE;
    border-radius: 15px !important;
    outline-color: #007bff;
}

.butterfly {
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
}

.border-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.submit-cancel-buttons {
    display: flex;
    justify-content: center;
    gap: 100px;
    margin-top: 15px;
    margin-bottom: 10px;;
}

.btn {
    padding: 10px 100px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn {
    background-color: #469FCE;
    color: white;
}

.cancel-btn {
    background-color: #E4976C;
    color: white;
}

.cancel-btn:hover {
    background-color: #ffd4b9;
}

.submit-btn:hover {
    background-color: #D1ECFB;
}

button.tab-btn {
    color: #E1EFFE;
    padding: 5px 30px;
    width: 150px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: bold;
    letter-spacing: 0.5px;
}

.notification-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }

  .cancel-confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    text-align: center;
    z-index: 1001; 
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal-content button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #469FCE;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .modal-content button:hover {
    background-color: #D1ECFB;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  
  

